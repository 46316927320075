<template>
  <r-e-dialog :title="(formPublish.uuid?'修改':'添加')+'杂费账单'" :visible.sync="dialogVisible" show-footer top="5vh"
              width="600px" @click-submit="handleSubmitPublish" @click-cancel="handleCancel" @close="handleCancel">
    <el-form title="" ref="formPublish" label-width="110px" size="small" :model="formPublish" :rules="rules">
      <div class="flex">
        <el-form-item label="房源信息">
          <el-input v-model="currentHouse.address" disabled placeholder="请填写房型名称（15个字以内）"/>
        </el-form-item>
      </div>
      <div class="flex">
        <el-form-item label="账单类型" prop="type">
          <el-select @change="newValue" v-model="formPublish.type" placeholder="账单类型">
            <el-option v-for="item in billsTypeList" :key="item.uuid" :label="item.codeName"
                       :value="item.code"/>
          </el-select>
        </el-form-item>
      </div>
      <div class="flex">
        <el-form-item label="账单周期">
          <el-date-picker @input="newValue" v-model="cycle" type="daterange" format="yyyy-MM-dd"
                          value-format="yyyy-MM-dd"
                          style="width: 100%;" range-separator="至" start-placeholder="开始日期"
                          end-placeholder="结束日期"/>
        </el-form-item>
      </div>
      <div class="flex">
        <el-form-item label="金额" prop="amount">
          <el-input-number :disabled="disabled" style="width: 100%;" v-model="formPublish.amount" controls-position="right"
                           :precision="2"/>
        </el-form-item>
      </div>
      <div class="flex">
        <el-form-item label="账单名称" prop="name">
          <el-input type="text" v-model="formPublish.name" placeholder="请输入内容"/>
        </el-form-item>
      </div>
      <div class="flex">
        <el-form-item label="账单备注" prop="comment">
          <el-input type="textarea" v-model="formPublish.comment" :rows="3" placeholder="请输入内容"/>
        </el-form-item>
      </div>
    </el-form>
  </r-e-dialog>
</template>

<script>
//添加杂费账单
import {addOtherBills, getHouseLeaserOtherBillsApi, updateOtherBills} from "@/api/house-resource";
import {getContractByUuidOrLeasorUuid} from "@/api/contract"
import {MessageSuccess, MessageWarning} from "@custom/message";

export default {
  name: "dialog-add-other-bill",
  data() {
    return {
      disabled:false,
      dialogVisible: false,
      formPublish: {
        amount: null,
        name: null,
        comment: null,
        type: null,
        endDate: new Date(),
        startDate: new Date(),
        leasorUuid: null,
        apartmentUuid: null,
        contractUuid: null,
      },
      rules: {
        type: [{required: true, message: '请选择账单类型', trigger: 'change'}],
        startDate: [{required: true, message: '请选择开始日期', trigger: 'change'}],
        endDate: [{required: true, message: '请选择结束日期', trigger: 'change'}],
        amount: [{required: true, message: '金额不能为空', trigger: 'blur'}],
        name: [{required: true, message: '账单名称不能为空', trigger: 'blur'},],
        comment: [{required: true, message: '账单名称不能为空', trigger: 'blur'},]
      },
      billsTypeList: [],
      cycle: [],
      loadingOptions: {
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      },
    }
  },
  props: {
    currentHouse: {
      type: Object,
      default: () => ({})
    },
    zUuid: {
      type: String,
      default: null,
    }
  },
  async created() {
    // 获取用户uuid, 房源uuid
    const {leasorUuid, apartmentUuid} = this.currentHouse;
    this.uuid = leasorUuid;

    //2022/03/21 王江毅 保存用户uuid, 房源uuid
    this.formPublish.leasorUuid = leasorUuid;
    this.formPublish.apartmentUuid = this.zUuid || apartmentUuid;

    //获取全部账单类型
    const list = await this.$store.dispatch('app/getDictionaryByCodeActions', '500500');
    //过滤账单类型，并赋值
    this.billsTypeList = list.filter(item => item.code !== 500504 && item.code !== 500505 && item.code !== 500506 && item.code !== 500508 && item.code !== 500510);
  },
  methods: {
    //打开添加杂费账单弹框方法
    async  openDialog(data) {
      const that = this;
      //拿出旧数据
      const oldFormPublish = this.formPublish;
      // data不等于null为修改，否则为添加
      if (data !== null) {
        //拆分数据
        let {uuid, type, amount, startDate, endDate, name, comment} = data;
        //赋值
        this.formPublish.uuid = uuid;
        //转换金额
        this.formPublish.amount = parseFloat((amount / 100).toFixed(2));
        //转换转单类型
        this.formPublish.type = parseInt(type);
        this.cycle = [new Date(startDate), new Date(endDate)];
        this.formPublish.name = name;
        this.formPublish.comment = comment;
      } else {
        //获取租户uuid，房间uuid
        const {leasorUuid} = this.currentHouse;
        const apartmentUuid = this.zUuid || this.currentHouse.apartmentUuid;
        const loadingOptions = that.loadingOptions;
        const loading = that.$loading({...loadingOptions});
        //调用接口获取合同uuid
        // let res = await getContractByUuidOrLeasorUuid({uuid: apartmentUuid, leasorUuid});
        // if (res) {
          //获取合同uuid
          // let {msg: contractUuid} = res;
          // 初始化对象
          this.formPublish = {...oldFormPublish, leasorUuid, apartmentUuid}
        // } else this.formPublish = {...oldFormPublish};
        this.cycle = [];
        loading.close();
      }
      this.dialogVisible = true;
    },
    async newGetLeasorUuid(data){
      // 获取最新的合同uuid事件
      const oldFormPublish = this.formPublish;
      //获取租户uuid，房间uuid
      const {leasorUuid} = this.currentHouse;
      const apartmentUuid = this.zUuid || this.currentHouse.apartmentUuid;
      //调用接口获取合同uuid
      let res = await getContractByUuidOrLeasorUuid({uuid: apartmentUuid, leasorUuid,...data});
      if (res) {
        //获取合同uuid
        const {msg: contractUuid} = res;
        // 初始化对象
        this.formPublish.contractUuid = contractUuid
      } else this.formPublish = {...oldFormPublish};
    },
    //添加杂费账单弹框确认事件
    handleSubmitPublish() {
      //2022/03/21 王江毅 that=this 通过that调用this的变量和方法 以免发生this指引异常
      let that = this;
      // 表单校验
      that.$refs["formPublish"].validate((valid) => {
        if (valid) {
          let cycle = that.cycle;
          if (!cycle || cycle.length === 0) {
            MessageWarning("账单周期不能为空");
            return;
          }

          //获取房租账单uuid
          let {uuid} = that.formPublish;
          //判断uuid存不存在，存在修改，否则添加
          if (uuid) that.updateOtherBills();
          else that.addOtherBills();
        } else {
          MessageWarning("请填写必填值", 1000);
          return false;
        }
      });

    },

    //添加杂费账单弹框取消事件
    handleCancel() {
      this.dialogVisible = false;
      this.formPublish = {
        amount: null, name: null, comment: null, type: null, endDate: new Date(), startDate: new Date(),
      }
      //2022/03/21 调用父组件方法 刷新未支付杂费账单列表
      this.$emit('clickCancel');
    },
    //添加杂费账单接口
    addOtherBills() {
      let that = this;
      const loadingOptions = that.loadingOptions;
      const loading = that.$loading({...loadingOptions});
      //2022/03/21 王江毅 解构出需要的字段 并赋值,以免双向数据绑定导致展示数据异常
      let {
        amount, apartmentName, apartmentUuid, aparttmentCount, comment, endDate, leasorName, leasorUuid,
        manual, name, paid, paidDate, startDate, type, contractUuid
      } = that.formPublish;
      aparttmentCount = 1;
      manual = false;
      paid = false;
      amount = parseInt((amount * 100).toFixed(0));
      //2022/03/21 王江毅 打包数据
      const paramData = {
        amount, apartmentName, apartmentUuid, aparttmentCount, comment, endDate, leasorName, leasorUuid,
        manual, name, paid, paidDate, startDate, type, contractUuid
      };
      //2022/03/21 王江毅 调用接口添加杂费账单
      addOtherBills(paramData).then(res => {
        MessageSuccess('添加杂费账单成功');
        //调用取消关闭弹框
        that.handleCancel();
      }).finally(() => loading.close());
    },

    //修改房租账单方法
    updateOtherBills() {
      let that = this;
      const loadingOptions = this.loadingOptions;
      const loading = this.$loading({...loadingOptions});
      //2022/03/21 王江毅 解构出需要的字段 并赋值,以免双向数据绑定导致展示数据异常
      let {amount, comment, endDate, name, startDate, type, uuid} = that.formPublish;
      amount = parseInt((amount * 100).toFixed(0));
      //2022/03/21 王江毅 打包数据
      let paramData = {amount, comment, endDate, name, startDate, type, uuid};
      //2022/03/21 王江毅 调用接口添加杂费账单
      updateOtherBills(paramData).then(res => {
        MessageSuccess('修改杂费账单成功');
        //调用取消关闭弹框
        that.handleCancel();
      }).finally(() => loading.close());
    },
    // 2024-5-30-陈守亮-新增杂费自动计算
   async  newValue() {
     await  this.newGetLeasorUuid({billStartDate: this.cycle[0], billEndDate : this.cycle[1]})
      if (this.formPublish.type === 500504 || this.formPublish.type === 500512 || this.formPublish.type === 500507) {
        this.disabled = true
        if (this.formPublish.type === null || this.cycle.length === 0) {
          return
        }

        const loadingOptions = this.loadingOptions;
        const loading = this.$loading({...loadingOptions});
        let data = {
          uuid: this.formPublish.contractUuid,
          type: this.formPublish.type,
          startDate: this.cycle[0],
          endDate: this.cycle[1],
        }
        getHouseLeaserOtherBillsApi(data).then(res => {
          this.formPublish.amount = res.info.amount / 100
          loading.close()
        }).catch(err => {
          loading.close()
          console.log(err)
          this.formPublish.amount = 0
          this.cycle = []
          this.formPublish.startDate = null
          this.formPublish.endDate = null
        })
      }else {
        this.disabled = false
      }
    }
  },
  watch: {
    cycle(value) {
      if (value) {
        let [startDate, endDate] = value;
        this.formPublish.startDate = startDate;
        this.formPublish.endDate = endDate;
      } else {
        this.formPublish.amount = 0
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.el-select {
  width: 100%;
}

.flex {
  display: flex;

  /deep/ .el-form-item {
    flex: 1;
    white-space: nowrap;
  }

  /deep/ .amount {
    .el-input {
      width: 42%;
    }
  }

  /deep/ .tag-group {
    white-space: normal;

    .el-tag {
      margin: 0 VW(5px) VH(5px) 0;
      cursor: pointer;
    }
  }

  /deep/ .samplePhotos {
    white-space: normal;
  }
}
</style>
<style>
.v-modal {
  z-index: 2000 !important;
}
</style>
